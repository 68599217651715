import { useState, useEffect } from "react";
import Link from "next/link";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import RightArrowIcon from "../public/img/right-arrow.svg";
import registerSchema from "../utils/registerSchema";
import { signIn } from "next-auth/client";
import ErrorAlert from "../components/alerts/error";
import SuccessAlert from "../components/alerts/success";
import Loader from "react-loader-spinner";
import api from "../src/api";
import Layout from "../components/common/layout";
import { getSession } from "next-auth/client";
import redirects from "../src/api/redirects";

import { getParams } from "../src/utils";

const login = () => {
  const [registerUrl, setRegisterUrl] = useState("/");

  const [loading, setLoading] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);
  const [successLogin, setSuccessLogin] = useState(false);
  const [message, setMessage] = useState("");
  // const [userId, setUserId] = useState(null);

  const schema = registerSchema([
    {
      name: "email",
      type: "Email",
    },
    {
      name: "password",
      type: "Password",
    },
  ]);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formData) => {
    setLoading(true);
    setErrorLogin(false);

    let cleanEmail = _.trimStart(formData.email);
    cleanEmail = _.trimEnd(cleanEmail);
    cleanEmail = _.toLower(cleanEmail);

    try {
      let params = getParams();

      const login = await new api.Login(
        cleanEmail,
        formData.password
      ).doMutation();

      if (!login || !login?.status) {
        setErrorLogin(true);
        setMessage(login.message);
        return;
      }

      if (login.status) {
        setSuccessLogin(true);
        // setUserId(login.user.id);
      }

      // const showPrescribingLink = await new api.ShowPrescribingLink(login.user.id).doQuery()

      let url = `${process.env.NEXTAUTH_URL}/${
        params?.status
          ? `${params.status}/${params.url ? params.url + "/" : ""}${
              params.url2 ? params.url2 : ""
            }${params.hasDiploma ? "?hasDiploma=true" : ""} `
          : ""
      }`;

      signIn("credentials", {
        email: formData.email,
        access_token: login.access_token,
        refresh_token: login.refresh_token,
        license_verified: login.user.license_verified,
        user_id: login.user.id,
        role: login.user.role,
        specialty_id: login.user.specialty.id,
        state_id: login.user.state.id,
        working_place_text: login.user.working_place_text,
        // showPrescribingLink: showPrescribingLink?.slug,
        callbackUrl: `${url}`,
      });
    } catch (err) {
      console.log(err);
      setErrorLogin(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setRegisterUrl(
      `/${
        getParams().status
          ? `?status=${getParams().status}&url=${getParams().url}${
              getParams().url2 ? "&url2=" + getParams().url2 : ""
            }`
          : ""
      }`
    );

    document.querySelector("body").classList.add("zendesk");

    return function unmount() {
      document.querySelector("body").classList.remove("zendesk");
    };
  }, []);

  return (
    <Layout pageTitle="Iniciar sesión">
      <div className="h-screen">
        <div
          className="h-screen w-screen flex justify-center items-center flex-col pb-20"
          style={{ background: "url(/img/login-bg-2.jpg) center no-repeat" }}
        >
          <div className="flex justify-center items-center flex-col lg:h-auto w-full lg:w-2/6 mt-2">
            <img
              alt="Bi logo"
              className="w-50 h-auto"
              src="img/back-Bi2-02.png"
            />
            <p className="text-2xl lg:text-3xl font-bold mt-20 lg:mt-6">
              Inicie Sesión
            </p>
            <div className="flex mb-8">
              <p className="text-base mr-1">O </p>

              <Link href={registerUrl}>
                <span className="text-bi-green-malachite-600 font-semibold cursor-pointer">
                  registre una nueva cuenta
                </span>
              </Link>
              <div className="flex justify-center items-center">
                <RightArrowIcon
                  width="15px"
                  height="15px"
                  style={{ color: "#00c064" }}
                />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-2/6 bg-white shadow-xl lg:rounded-lg p-8 lg:h-auto">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col items-stretch mb-5">
                <label
                  htmlFor="email"
                  className="text-sm font-medium text-gray-600"
                >
                  Correo electrónico
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="given-name"
                    className="w-full py-2 px-4 shadow-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-lg my-2"
                    ref={register}
                  />
                  <p className="text-xs text-red-400 pl-5">
                    {errors.email?.message && "El correo es obligatorio"}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-stretch mb-1">
                <label
                  htmlFor="password"
                  className="text-sm font-medium text-gray-600"
                >
                  Contraseña
                </label>
                <div className="w-full">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    autoComplete="given-name"
                    className="w-full py-2 px-4 shadow-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-lg my-2"
                    ref={register}
                  />
                  <p className="text-xs text-red-400 pl-5">
                    {errors.password?.message && "La contraseña es obligatoria"}
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-end">
                <div className="text-sm">
                  <Link
                    href="/restablecer-contrasena"
                    className="font-medium text-bi-green-malachite-600"
                  >
                    ¿Olvidó su contraseña?
                  </Link>
                </div>
              </div>
              <div className="w-full flex justify-center my-5">
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full flex justify-center items-end gap-x-1 py-2 mt-5 px-4 border border-transparent rounded-md shadow-sm text-sm tracking-widest uppercase font-normal text-white bg-bi-green-deep-teal-600 hover:bi-green-deep-teal-300 focus:outline-none"
                >
                  <span>Iniciar Sesión</span>
                  {loading && (
                    <Loader
                      className=""
                      type="ThreeDots"
                      color="#FFFFFF"
                      height={10}
                      width={15}
                    />
                  )}
                </button>
              </div>
            </form>

            <div className="mt-4 flex align-middle justify-center">
              {errorLogin && <ErrorAlert message={message} />}
              {successLogin && <SuccessAlert message={"Iniciando sesión"} />}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default login;

export async function getServerSideProps(context) {
  const session = await getSession(context);
  if (session) return redirects.guest();

  return {
    props: {
      redirect: null,
    },
  };
}
